.textFieldContainer {
  width: 28%;
}

.orderChecker-checklist {
    margin-top: 24px;
}

.orderChecker-checklist h3 {
    font-weight: 600;
    margin-bottom: 4px;
    text-decoration: underline;
}

.orderChecker-tooltip .Polaris-Icon {
    width: 16px;
    height: 16px;
}

.orderChecker-checklist table {
    padding: 0;
    border-spacing: 0;
}

.orderChecker-checklist tr:nth-child(odd) {
    background-color: #f7f7f7;
}

.orderChecker-checklist td {
    padding: 2px 4px;
}

.orderChecker-checklist .user_value {
    display: inline-flex;
    align-items: flex-start;
}

.orderChecker-checklist .value {
    text-align: right;
}

.orderChecker-checklist .Polaris-Icon {
    display: inline-block;
    margin: 0 4px 0 0;
}

.orderChecker-checkedAt {
    margin-top: 8px;
    font-size: 11px;
    color: #666666;
}

.orderChecker-content {
    display: flex;
    gap: 20px;
}

.orderChecker-content pre {
    margin-top: 23px;
    padding: 20px;
    background-color:#f9f9f9;
    overflow: auto;
    border-radius: 8px;
}

.orderChecker-content > :first-child {
    flex-shrink: 0 !important;
}

@media screen and (max-width: 1000px) {
    .textFieldContainer{
      width: 40%;
    }
  }

  @media screen and (max-width: 500px){
    .textFieldContainer{
        width: 100%;
    }
  }
